require('./bootstrap');
require('jquery');

import Quill from 'quill';
import htmlEditButton from "quill-html-edit-button";

import "./modules/theme";
import "./modules/feather";
import "./modules/moment";
import "./modules/sidebar";

// Forms
import "./modules/flatpickr";


$('.form-delete').on('submit', function (e) {
    e.preventDefault();

    $('#confirmDestroyModal form').attr('action', $(this).attr('action'));

    console.log($(this).attr('action'));
});



Quill.register({
    "modules/htmlEditButton": htmlEditButton
});

var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],

    [{
        'header': 1
    }, {
        'header': 2
    }], // custom button values
    [{
        'list': 'ordered'
    }, {
        'list': 'bullet'
    }],
    [{
        'script': 'sub'
    }, {
        'script': 'super'
    }], // superscript/subscript
    [{
        'indent': '-1'
    }, {
        'indent': '+1'
    }], // outdent/indent
    [{
        'direction': 'rtl'
    }], // text direction

    [{
        'size': ['small', false, 'large', 'huge']
    }], // custom dropdown
    [{
        'header': [1, 2, 3, 4, 5, 6, false]
    }],

    ['link', 'image', 'video', 'formula'],

    // [{
    //     'color': []
    // }, {
    //     'background': []
    // }], // dropdown with defaults from theme
    // [{
    //     'font': []
    // }],

    [{
        'align': []
    }],

    ['clean'] // remove formatting button
];

$('.quill-editor').each(function (i, el) {
    var el = $(this),
        id = 'quilleditor-' + i,
        val = el.val(),
        editor_height = 300;

    var div = $('<div/>').attr('id', id).css('height', editor_height + 'px').html(val);

    el.addClass('d-none');
    el.parent().append(div);

    var quill = new Quill('#' + id, {
        modules: {
            toolbar: toolbarOptions,

            htmlEditButton: {

            }
        },
        theme: 'snow'
    });

    quill.on('text-change', function () {
        el.val(quill.container.firstChild.innerHTML);
    });
});
